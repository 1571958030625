.generator {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 20vw;
}

.controls {
    width: 40%;
    margin-left: 12px;
    text-align: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.controls .count {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.controls input[type=number] {
    width: 50px;
}
.controls label:not(:first-child) {
    margin-top: 8px;
}